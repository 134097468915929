<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <img
                      src="@/assets/images/logo/logo bioherbs-01.png"
                      width="200px"
                      height="auto"
                    >
                    <h3 class="text-primary invoice-logo" />
                  </div>
                  <b-card-text class="mb-0">
                    Route Lido Dar Chabane , 8000 Nabeul
                  </b-card-text>
                  <b-card-text class="mb-0">
                    contact@bioherbs.tn
                  </b-card-text>
                  <b-card-text class="mb-0">
                    www.bioherbs.tn
                  </b-card-text>
                  <b-card-text class="mb-0">
                    +216 24 289 289
                  </b-card-text>
                  <b-card-text class="mb-0">
                    MF : 000MB1704494/H
                  </b-card-text>

                </div>
                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Avoir
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="generate"
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Date:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.date"
                      class="form-control invoice-edit-input"
                    />
                  </div>

                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Avoir pour:
                  </h6>
                  <!-- Select Client -->
                  <v-select
                    v-model="invoiceData.client"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="clients"
                    label="company_name"
                    input-id="invoice-data-client"
                    :clearable="false"
                  >
                    <template #list-header>
                      <li
                        v-b-toggle.sidebar-invoice-add-new-customer
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-25">Ajouter un noveau client</span>
                      </li>
                    </template>
                  </v-select>

                  <!-- Selected Client -->
                  <div
                    v-if="invoiceData.client"
                    class="mt-1"
                  >
                    <h6
                      class="mb-25"
                    >
                      Client : {{ invoiceData.client.company_name }}
                    </h6>
                    <b-card-text class="mb-25">
                      Adresse : {{ invoiceData.client.address }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      Email : {{ invoiceData.client.email }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      Tel : {{ invoiceData.client.phone }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      MF/CIN : {{ invoiceData.client.tax_identification_number }}
                    </b-card-text>
                  </div>
                </b-col>
                <feather-icon
                  size="16"
                  icon="XOctagonIcon"
                  class="cursor-pointer mt-4"
                  @click="invoiceData.client=null"
                />

              </b-row>
            </b-card-body>
            <!-- Items Section -->

            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{height: trHeight}"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">

                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Référence
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Labelle
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Lot
                        </b-col>
                        <b-col
                          v-if="invoiceData.client && invoiceData.client.type !== 'client physique'"
                          cols="12"
                          lg="2"
                        >
                          <span>Prix </span>
                        </b-col>
                        <b-col
                          v-else
                          cols="12"
                          lg="2"
                        >
                          Prix
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Quantité
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Prix total
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Item</label>
                          <v-select
                            v-model="item.produit"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="itemsOptions"
                            label="reference"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            @input="val => updateItemForm(index, val)"
                          />

                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Labelle</label>
                          <b-form-input
                            v-if="item.produit.reference != '' && item.produit.name == ''"
                            v-model="item.produit.name"
                            class="mb-2"
                          />
                          <v-select
                            v-else
                            v-model="item.produit"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="itemsOptions"
                            label="name"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            @input="val => updateItemForm(index, val)"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Lot</label>
                          <b-form-input
                            v-model="item.produit.lot"
                            value="1"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          v-if="invoiceData.client && invoiceData.client.type !== 'client physique'"
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Prix de gros</label>
                          <b-form-input
                            v-model="item.produit.wholesale_price"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          v-else
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Prix de vente</label>
                          <b-form-input
                            v-model="item.produit.sales_price"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Qty</label>
                          <b-form-input
                            v-model="item.qty"
                            value="1"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          v-if="invoiceData.client && invoiceData.client.type === 'client physique'"
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Prix</label>
                          <p class="mb-1">
                            {{ (item.produit.sales_price * item.qty).toFixed(3) }}
                          </p>

                        </b-col>
                        <b-col
                          v-if="invoiceData.client && invoiceData.client.type === 'client morale'"

                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Prix</label>
                          <p class="mb-1">
                            {{ (item.produit.wholesale_price * item.qty).toFixed(3) }}
                          </p>
                        </b-col>
                        <b-col
                          v-else

                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Prix</label>
                          <p class="mb-1">
                            {{ (item.produit.sales_price * item.qty).toFixed(3) }}
                          </p>
                        </b-col>

                      </b-row>
                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />

                        <b-popover
                          :ref="`form-item-settings-popover-${index}`"
                          :target="`form-item-settings-icon-${index}`"
                          triggers="click"
                          placement="lefttop"
                        >
                          <b-form @submit.prevent>
                            <b-row>

                              <!-- Field: Discount -->
                              <b-col cols="12">
                                <b-form-group
                                  label="Discount(%)"
                                  :label-for="`setting-item-${index}-discount`"
                                >
                                  <b-form-input
                                    :id="`setting-item-${index}-discount`"
                                    :value="null"
                                    type="number"
                                  />
                                </b-form-group>
                              </b-col>

                              <!-- Field: Tax 1 -->
                              <b-col cols="6">
                                <b-form-group
                                  label="Tax 1"
                                  :label-for="`setting-item-${index}-tax-1`"
                                >
                                  <v-select
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :value="'10%'"
                                    :options="['0%', '1%', '10%', '14%', '18%']"
                                    :input-id="`setting-item-${index}-tax-1`"
                                    :clearable="false"
                                  />
                                </b-form-group>
                              </b-col>

                              <!-- Field: Tax 2 -->
                              <b-col cols="6">
                                <b-form-group
                                  label="Tax 2"
                                  :label-for="`setting-item-${index}-tax-2`"
                                >
                                  <v-select
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :value="'10%'"
                                    :options="['0%', '1%', '10%', '14%', '18%']"
                                    :input-id="`setting-item-${index}-tax-2`"
                                    :clearable="false"
                                  />
                                </b-form-group>
                              </b-col>

                              <b-col
                                cols="12"
                                class="d-flex justify-content-between mt-1"
                              >
                                <b-button
                                  variant="outline-primary"
                                  @click="() => {$refs[`form-item-settings-popover-${index}`][0].$emit('close')}"
                                >
                                  Apply
                                </b-button>
                                <b-button
                                  variant="outline-secondary"
                                  @click="() => {$refs[`form-item-settings-popover-${index}`][0].$emit('close')}"
                                >
                                  Cancel
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-form>
                        </b-popover>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Ajouter produit
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                  <label
                    for="invoice-data-sales-person"
                    class="text-nowrap mr-50"
                  >Remise</label>
                  <b-form-input
                    id="invoice-data-sales-person"
                    v-model="remise"
                    placeholder="Edward Crowley"
                  />
                  <span class="ml-2">%</span>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div
                      v-if="somme"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Total HT:
                      </p>
                      <p class="invoice-total-amount">
                        {{ somme.toFixed(3) }} DT
                      </p>
                    </div>
                    <div
                      v-else
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Total HT:
                      </p>
                      <p class="invoice-total-amount">
                        0
                      </p>
                    </div>

                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Tax 19 %:
                      </p>
                      <p
                        v-if="somme"
                        class="invoice-total-amount"
                      >
                        {{ tax }} DT
                      </p>
                      <p
                        v-else
                        class="invoice-total-amount"
                      >
                        0
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Timbre fiscal:
                      </p>
                      <p class="invoice-total-amount">
                        1 DT
                      </p>
                    </div>
                    <hr class="my-50">
                    <div
                      v-if="somme"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Prix T.T.C:
                      </p>
                      <p class="invoice-total-amount">
                        {{ sommeTTC }} DT
                      </p>
                    </div>
                    <div
                      v-else
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Prix T.T.C:
                      </p>
                      <p class="invoice-total-amount">
                        0
                      </p>
                    </div>
                  </div></b-col></b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <quill-editor
                ref="myQuillEditor"
                v-model="invoiceData.note"
              />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <b-card>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click="save"
          >
            Ajouter
          </b-button>
        </b-card>

      </b-col>
    </b-row>
    <invoice-sidebar-add-new-customer />

  </section>

</template>

<script>
import { ref } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BPopover, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { quillEditor } from 'vue-quill-editor'
import InvoiceSidebarAddNewCustomer from './AddClient.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BPopover,
    flatPickr,
    vSelect,
    InvoiceSidebarAddNewCustomer,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    quillEditor,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],

  data() {
    return {
      last: [],
      status: '',
    }
  },
  computed: {

    generate() {
      return this.last + 1
    },

    somme() {
      if (this.remise === 0) {
        if ((this.invoiceData.client === null) || (this.invoiceData.client && this.invoiceData.client.type === 'client physique')) {
          return this.invoiceData.items.reduce((accumulator, item) => accumulator + (item.produit.sales_price * item.qty), 0)
        }
        return this.invoiceData.items.reduce((accumulator, item) => accumulator + (item.produit.wholesale_price * item.qty), 0)
      }

      if ((this.invoiceData.client === null) || (this.invoiceData.client && this.invoiceData.client.type === 'client physique')) {
        const somme = this.invoiceData.items.reduce((accumulator, item) => accumulator + (item.produit.sales_price * item.qty), 0)
        return (somme - ((somme * this.remise) / 100))
      }
      const somme = this.invoiceData.items.reduce((accumulator, item) => accumulator + (item.produit.wholesale_price * item.qty), 0)
      return (somme - ((somme * this.remise) / 100))
    },
    sommeTTC() {
      return ((Number(this.somme) * (1 + (19 / 100))) + 1).toFixed(3)
    },
    tax() {
      return (Number(this.somme) / (100 / 19)).toFixed(3)
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    this.getLastAvoir()

    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    async getLastAvoir() {
      await axios.get('/avoirs/last/').then(response => {
        this.last = response.data
      })
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)

      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')}`
    },
    async save() {
      const date = new Date()
      const now = this.formatFn(date.toISOString())
      let customer
      if (this.invoiceData.client) {
        customer = this.invoiceData.client.id
        if (this.invoiceData.client.type === 'client physique') {
          this.status = 'vente direct'
        } else {
          this.status = 'vente gros'
        }
      } else {
        customer = null
        this.status = 'vente direct'
      }

      const obj = {
        reference: this.generate,
        date: this.invoiceData.date,
        status: this.status,
        note: this.invoiceData.note,
        customer,
        remise: this.remise,
        products: this.invoiceData.items,
        total_ht: this.somme.toFixed(3),
        total_ttc: this.sommeTTC,
        tax: this.tax,
        date_today: now,
      }
      await axios.post('/avoirs/create/', obj).then(() => {
        this.$router.push({
          name: 'avoir-list',
        })
        this.showToast('success', 'top-center', 'Avoir ajouté avec succés')
      })
        .catch(error => {
          this.showToast('danger', 'top-center', error.toString())
        })
    },

    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    const clients = ref([])
    axios.get('/customers/').then(response => {
      clients.value = response.data
    })

    const itemFormBlankItem = {
      item: null,
      produit: {
        wholesale_price: 0,
        reference: '',
      },
      qty: 0,
    }
    const current = new Date()

    const invoiceData = ref({
      id: '',
      client: null,
      date: `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      salesPerson: '',
      note: '',
      paymentMethod: null,
    })

    const itemsOptions = ref([])
    axios.get('/products/').then(response => {
      itemsOptions.value = response.data
    })
    const remise = 0

    const updateItemForm = (index, val) => {
      const { cost, qty } = val
      invoiceData.value.items[index].cost = cost
      invoiceData.value.items[index].qty = qty
    }

    const paymentMethods = [
      'Bank Account',
      'PayPal',
      'UPI Transfer',
    ]

    return {
      invoiceData,
      clients,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,
      paymentMethods,
      remise,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
